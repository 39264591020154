// Footer.js
import React from "react";
import { motion } from "framer-motion";
import { Box, Container, Typography, Link, IconButton } from "@mui/material";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import "../App.css"; // Import your CSS file if needed

const footerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.8,
      ease: "easeInOut",
    },
  },
};

const Footer = () => {
  return (
    <motion.footer
      className="footer"
      initial="hidden"
      animate="visible"
      variants={footerVariants}
      id="footer"
    >
      <Container maxWidth="lg">
        <Box py={4} textAlign="center">
          <motion.div
            className="footer-text"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 1 }}
          >
            <Typography variant="body1" color="textSecondary">
              &copy; 2024 Dalemallen. All rights reserved.
            </Typography>
          </motion.div>
          <motion.div
            className="social-icons"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1, duration: 1 }}
          >
            {/* <IconButton
              component={Link}
              href="#"
              target="_blank"
              rel="noopener"
            >
              <GitHubIcon />
            </IconButton> */}
            <IconButton
              component={Link}
              href="https://www.linkedin.com/in/dale-allen-7a047078/"
              target="_blank"
              rel="noopener"
            >
              <LinkedInIcon />
            </IconButton>
            <IconButton
              component={Link}
              href="mailto:dale.m.allen@hotmail.com?subject=Lets Talk from dalemallen.com"
              target="_blank"
              rel="noopener"
            >
              <EmailIcon />
            </IconButton>
          </motion.div>
        </Box>
      </Container>
    </motion.footer>
  );
};

export default Footer;
