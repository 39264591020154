import React from "react";
import { Container, Grid, Typography, Box, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { PictureAsPdf } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";

const About = () => {
  return (
    <Grid container item xs={12}>
      <Container
        component={motion.div}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        maxWidth="md"
        sx={{ mt: 5 }}
      >
        <Box
          component={motion.div}
          initial={{ x: -100 }}
          animate={{ x: 0 }}
          transition={{ type: "spring", stiffness: 50 }}
          width="100%"
        >
          <Typography variant="h4" component="h2" gutterBottom>
            About Me{" "}
            <a
              href="./DaleAllen_2024_Resume_v3.pdf"
              style={{ color: "inherit" }}
              target="__blank"
            >
              {/* <Tooltip title="download PDF"> */}
              <PictureAsPdf
                id="downloadPdf"
                fontSize="larg"
                sx={{ marginLeft: 5 }}
              />
              {/* </Tooltip> */}
            </a>
          </Typography>

          <Typography variant="body1" component="p" gutterBottom>
            Seasoned Front-End Developer with over 6 years of experience in
            designing and implementing user-driven, responsive web applications.
            Proficient in HTML, CSS, JavaScript, React, and various CMS
            platforms. Demonstrated ability to enhance site accessibility,
            optimize front-end performance, and ensure cross-browser
            compatibility. Skilled in working independently or as part of a
            team, with a proven track record of mentoring junior developers.
            Committed to staying current with emerging trends and technologies,
            bringing strong conceptual thinking and adaptability to any team.
          </Typography>
        </Box>
      </Container>
    </Grid>
  );
};

export default About;
