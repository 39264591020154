import React from "react";

import Home from "./Home";
import About from "./About";
import WorkExperience from "./WorkExperience";
import Education from "./Education";
import Skills from "./Skills";
import { Container, Typography, Button, Grid } from "@mui/material";
import { Link } from "react-router-dom";

import Footer from "./Footer";

const CV = () => {
  return (
    <Grid
      container
      item
      // justifyContent="center"
      // alignItems="center"
      xs={12}
      // sx={{ minHeight: "100vh" }}
    >
      <Grid
        container
        item
        // justifyContent="center"
        // alignItems="center"
        xs={12}
        // sx={{ minHeight: "100vh" }}
      >
        <About />
      </Grid>
      <WorkExperience />
      <Education />
      <Skills />
      <Container>
        <Button
          variant="outlined"
          // sx={{
          //   borderRadius: "1px",
          //   borderColor: "black",
          //   color: "black",
          // }}
          sx={{ marginBottom: 3 }}
          width="100%"
          to="/"
          component={Link}
        >
          Back
        </Button>
      </Container>
    </Grid>
  );
};

export default CV;
