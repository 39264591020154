import React from "react";
import "./App.css";
import Home from "./components/Home";
import About from "./components/About";
import WorkExperience from "./components/WorkExperience";
import Education from "./components/Education";
import Skills from "./components/Skills";
import Contact from "./components/Contact";
import CV from "./components/CV";

import { AppBar, Toolbar, Typography, Link } from "@mui/material";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Footer from "./components/Footer";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route exact path="/cv" element={<CV />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
