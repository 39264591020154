import React from "react";
import {
  Container,
  Grid,
  Typography,
  Box,
  Card,
  CardContent,
} from "@mui/material";
import { motion } from "framer-motion";

const educationDetails = [
  {
    degree: "Diploma in Computer Information Systems (CIS)",
    institution: "Holland College",
    date: "May 2018",
    location: "Charlottetown, PE",
  },
];

const Education = () => (
  <Grid container item xs={12}>
    <Container
      component={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      maxWidth="md"
      sx={{ mt: 5 }}
    >
      <Typography variant="h4" component="h2" gutterBottom>
        Education
      </Typography>
      {educationDetails.map((education, index) => (
        <Card
          component={motion.div}
          key={index}
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: index * 0.2 }}
          sx={{ mb: 4 }}
        >
          <CardContent>
            <Typography variant="h5" component="h3">
              {education.degree}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {education.institution}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {education.date} | {education.location}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </Container>
  </Grid>
);

export default Education;
