import React from "react";
import { Container, Box, Typography, Button, Grid } from "@mui/material";
import { motion } from "framer-motion";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import GitHubIcon from "@mui/icons-material/GitHub";
import SummarizeIcon from "@mui/icons-material/Summarize";
import Card from "@mui/material/Card";
import Paper from "@mui/material/Paper";
import CardContent from "@mui/material/CardContent";
import me from "../me.JPG";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link } from "react-router-dom";
import SkillList from "../components/SkillList";
// import "./Home.css";
import "../App.css"; // Import your CSS file if needed
import Navbar from "./Navbar";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});
const svgVariants = {
  hidden: { opacity: 0, pathLength: 0 },
  visible: {
    opacity: 1,
    pathLength: 1,
    transition: {
      duration: 2,
      ease: "easeInOut",
    },
  },
};
const Home = () => {
  return (
    <ThemeProvider theme={theme}>
      {/* <Navbar /> */}
      <Grid
        container
        item
        justifyContent="center"
        alignItems="center"
        xs={12}
        sx={{ minHeight: "100vh" }}
      >
        <Grid
          container
          item
          justifyContent="center"
          alignItems="center"
          xs={10}
          md={8}
          lg={6}
        >
          <Grid
            container
            item
            flexDirection="column"
            xs={12}
            md={6}
            sx={{
              [theme.breakpoints.down("md")]: {
                order: 2,
              },
            }}
          >
            <Typography variant="h6" fontWeight="bold">
              Experienced
            </Typography>
            <Typography
              variant="h2"
              fontWeight="bold"
              sx={{
                fontSize: { xs: "3.25rem", sm: "3.25rem" },
              }}
            >
              <span style={{ color: "rgba(98, 182, 203, 1)" }}>
                {" "}
                Front End Developer
              </span>
              <Typography variant="h6" fontWeight="bold" marginTop={1}>
                6 + Years of Industry Experience
              </Typography>
            </Typography>
            <Typography variant="h4" gutterBottom marginTop={2}>
              Crafting Intuitive <br />
              and Engaging <br />
              Web Experiences
            </Typography>
            {/* <Typography variant="p" marginTop={3} width="80%">
              I have honed my skills in front-end development using technologies
              like React and Node.js. Throughout my career, I have successfully
              delivered projects, exceeding client expectations and achieving
              key milestones.
            </Typography> */}
            <Grid
              container
              item
              xs={12}
              md={6}
              marginTop={3}
              justifyContent="space-between"
              spacing={1}
              sx={{
                [theme.breakpoints.down("md")]: {
                  marginTop: 5,
                  marginBottom: 5,
                },
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  borderRadius: "1px",
                  borderColor: "rgba(98, 182, 203, 1)",
                  color: "rgba(98, 182, 203, 1)",
                }}
                width="100%"
                to="cv"
                component={Link}
              >
                Learn More
              </Button>
              <Button
                href="mailto:dale.m.allen@hotmail.com?subject=Lets Talk from dalemallen.com"
                target="_blank"
                rel="noopener"
                sx={{
                  color: "rgba(98, 182, 203, 1)",
                }}
              >
                Contact Me
              </Button>
            </Grid>
          </Grid>

          <Grid
            container
            item
            alignItems="center"
            xs={12}
            md={6}
            sx={{
              justifyContent: { xs: "center", md: "left" },
              [theme.breakpoints.down("md")]: {
                order: 1,
                marginTop: 5,
                marginBottom: 5,
              },
            }}
          >
            <Container
              component={motion.div}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
              sx={{ mt: 5, textAlign: "center" }}
            >
              <Box
                component={motion.div}
                initial={{ y: -100 }}
                animate={{ y: 0 }}
                transition={{ type: "spring", stiffness: 50 }}
              >
                <Box
                  component="img"
                  sx={{
                    height: 600,
                    width: 600,
                    maxHeight: { xs: 200, sm: 300 },
                    maxWidth: { xs: 200, sm: 300 },
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                  alt="profile"
                  src={me}
                />
              </Box>
            </Container>
          </Grid>
        </Grid>
        <Grid xs={12}>
          <SkillList />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Home;
