import React from "react";
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { motion } from "framer-motion";

const workExperiences = [
  {
    title: "Intermediate Front-End Developer, Red Label Vacations Inc.",
    date: "May 2022 – April 2024",
    location: "Toronto, ON",
    responsibilities: [
      "Developed user-driven websites from responsive designs using HTML5, ES6, CSS, SASS, React, PHP, Node.js, and SQL.",
      "Integrated compliance, translations, and web accessibility features, enhancing user experience for a a diverse client bases.",
      "Collaborated across departments to support end-to-end software development, significantly reducing cross-functional communication barriers.",
      "Mentored junior developers and promoted team collaboration.",
      "Improved responsiveness across various web and mobile applications.",
    ],
  },
  {
    title: "Front-End Developer, Freelance (TrailerWorks)",
    date: "September 2021 – May 2022",
    location: "Toronto, ON",
    responsibilities: [
      "Designed and implemented responsive UIs from mockups, achieving high fidelity and cross-browser compatibility.",
      "Enhanced site accessibility, contributing to an improvement in accessibility scores across multiple projects.",
      "Deployed with Git and GitLab CI, managing multiple database synchronizations to improve deployment reliability and data consistency.",
      "Utilized Webpack and Gulp to efficiently compile Less to CSS, optimizing load times and streamlining development workflows.",
    ],
  },
  {
    title: "Full Stack Developer, Level",
    date: "January 2020 – September 2021",
    location: "Toronto, ON",
    responsibilities: [
      "Developed and maintained ReactJS web and mobile applications.",
      "Implemented custom analytics features providing actionable insights, enhancing user engagement by 15%.",
      "Engineered a robust sign-up workflow that increased user acquisition by 25%.",
      "Conducted peer reviews, maintaining high coding standards and ensuring quality deliveries in agile sprint cycles.",
    ],
  },
  {
    title: "Full Stack Developer, Pixelbot",
    date: "July 2018 – August 2019",
    location: "Toronto, ON",
    responsibilities: [
      "Developed and maintained websites and web-based applications using various languages and technologies.",
      "Worked with CMS platforms such as WordPress and Shopify.",
      "Translated UI mockups into HTML and optimized front-end performance, ensuring web accessibility and responsiveness.",
      "Participated in agile development processes, including daily scrums, client communications, and cross-departmental coordination.",
      "Applied object-oriented concepts and MVC architecture, performing CRUD operations within MySQL databases and managing version control systems.",
    ],
  },
];

const WorkExperience = () => (
  <Container
    component={motion.div}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 1 }}
    maxWidth="md"
    sx={{ mt: 5 }}
  >
    <Typography variant="h4" component="h2" gutterBottom>
      Work Experience
    </Typography>
    {workExperiences.map((experience, index) => (
      <Card
        component={motion.div}
        key={index}
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: index * 0.2 }}
        sx={{ mb: 4 }}
      >
        <CardContent>
          <Typography variant="h5" component="h3">
            {experience.title}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {experience.date} | {experience.location}
          </Typography>
          <List sx={{ listStyleType: "disc", paddingLeft: 3 }}>
            {experience.responsibilities.map((responsibility, idx) => (
              <ListItem key={idx} disablePadding sx={{ display: "list-item" }}>
                <ListItemText primary={responsibility} />
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>
    ))}
  </Container>
);

export default WorkExperience;
