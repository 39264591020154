import React from "react";
import { Container, Typography, Box, Grid, Paper } from "@mui/material";
import { motion } from "framer-motion";

const Skills = () => {
  const skillsList = [
    {
      category: "Front-End Development",
      skills: [
        "HTML",
        "CSS",
        "SASS",
        "JavaScript",
        "React",
        "Redux",
        "Material UI",
        "Bootstrap",
        "Angular",
        "Webpack",
      ],
    },
    {
      category: "Back-End Development",
      skills: ["Node.js", "PHP", "MySQL", "Oracle", "AWS", "C#", "Java"],
    },
    {
      category: "CMS Platforms",
      skills: ["WordPress", "Shopify"],
    },
    {
      category: "Tools and Version Control",
      skills: ["Git", "GitHub", "GitLab", "Bitbucket"],
    },
    {
      category: "Other Skills",
      skills: [
        "Responsive Design",
        "Web Accessibility",
        "Web APIs",
        "Agile Methodologies",
      ],
    },
  ];

  return (
    <Grid container item xs={12}>
      <Container
        component={motion.div}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        maxWidth="md"
        sx={{ mt: 5 }}
      >
        <Typography variant="h4" component="h2" gutterBottom>
          Skills
        </Typography>
        {skillsList.map((skillCategory, index) => (
          <Box
            component={motion.div}
            key={index}
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: index * 0.2 }}
            sx={{ mb: 4 }}
          >
            <Typography variant="h5" component="h3" gutterBottom>
              {skillCategory.category}
            </Typography>
            <Grid container spacing={2}>
              {skillCategory.skills.map((skill, idx) => (
                <Grid item xs={6} sm={4} md={3} key={idx}>
                  <Paper elevation={3} sx={{ p: 2, textAlign: "center" }}>
                    <Typography variant="body1">{skill}</Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Box>
        ))}
      </Container>
    </Grid>
  );
};

export default Skills;
