import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Grid,
  Paper,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { motion } from "framer-motion";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
// import logo from "/html-5-logo-svgrepo-com.svg";
const SkillList = () => {
  return (
    <Grid
      container
      item
      justifyContent="center"
      alignItems="center"
      sx={{
        borderRadius: "30px",
      }}
      xs={12}
      id="skillList"
      // padding={3}
    >
      {/* <Grid container item xs={12} justifyContent="center" alignItems="center">
        <Typography variant="h5" paddingBottom={4}>
          <strong>Tech Stack</strong>
        </Typography>
      </Grid> */}
      <Grid container xs={12}>
        <Grid
          container
          item
          xs={12}
          justifyContent="space-evenly"
          alignItems="center"
        >
          <img
            src="/html-5-logo-svgrepo-com.svg"
            style={{
              maxWidth: "100%",
              height: "5em",
              padding: 0,
              margin: 0,
              maxHeight: "170px",
            }}
          />
          <img
            src="/css-3-svgrepo-com.svg"
            style={{
              maxWidth: "100%",
              height: "5em",
              padding: 0,
              margin: 0,
              maxHeight: "170px",
            }}
          />
          <img
            src="/javascript-logo-svgrepo-com.svg"
            style={{
              maxWidth: "100%",
              height: "5em",
              padding: 0,
              margin: 0,
              maxHeight: "170px",
            }}
          />
          <img
            src="/php-svgrepo-com.svg"
            style={{
              maxWidth: "100%",
              height: "5em",
              padding: 0,
              margin: 0,
              maxHeight: "170px",
            }}
          />
          <img
            src="/react-svgrepo-com.svg"
            style={{
              maxWidth: "100%",
              height: "5em",
              padding: 0,
              margin: 0,
              maxHeight: "170px",
            }}
          />
          <img
            src="/node-js-svgrepo-com.svg"
            style={{
              maxWidth: "100%",
              height: "5em",
              padding: 0,
              margin: 0,
              maxHeight: "170px",
            }}
          />
          <img
            src="/api-interface-svgrepo-com.svg"
            style={{
              maxWidth: "100%",
              height: "5em",
              padding: 0,
              margin: 0,
              maxHeight: "170px",
            }}
          />
          <img
            src="/mysql-svgrepo-com.svg"
            style={{
              maxWidth: "100%",
              height: "5em",
              padding: 0,
              margin: 0,
              maxHeight: "170px",
            }}
          />

          <img
            src="/material-ui-svgrepo-com.svg"
            style={{
              maxWidth: "100%",
              height: "5em",
              padding: 0,
              margin: 0,
              maxHeight: "170px",
            }}
          />

          <img
            src="/brand-sass-svgrepo-com.svg"
            style={{
              maxWidth: "100%",
              height: "5em",
              padding: 0,
              margin: 0,
              maxHeight: "170px",
            }}
          />
          <img
            src="/tailwind-svgrepo-com.svg"
            style={{
              maxWidth: "100%",
              height: "5em",
              padding: 0,
              margin: 0,
              maxHeight: "170px",
            }}
          />
          <img
            src="/aws-svgrepo-com.svg"
            style={{
              maxWidth: "100%",
              height: "5em",
              padding: 0,
              margin: 0,
              maxHeight: "170px",
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SkillList;
